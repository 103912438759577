import React from "react"

const PageNotFound = () => {
  return (
    <div className="mainContainer errorContainer">
      <h1 className="errorPageh1">Page not found</h1>
      <h2>Sorry - we couldn't find what you were looking for.</h2>
      <a href="http://www.webdevolution.com"> webdevolution.com homepage </a>
    </div>
  )
}

export default PageNotFound
